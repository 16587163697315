// @flow
import * as Sentry from '@sentry/browser';
import io from 'socket.io-client';
import type { Saga } from 'redux-saga';
import {
    call, put, race, take, delay,
} from 'redux-saga/effects';

import { setMessageStatus, receiveNewMessage, triggerChatSocket } from '../../Redux/ChatListActions';
import { getResultChannel } from './Channels';
import createUuid from '../../Utils/createUuid';
import type { ChatroomType, PostAttachmentData } from '../../Utils/types';
import hasValue from '../../Utils/hasValue';
import devLog from '../../Utils/logger';

export type ChatCommand =
    | 'get-application-data'
    | 'send-message-v2'
    | 'read-messages'
    | 'clear-notifications'
    | 'add-user-v2'
    | 'resend-notification'
    | 'remove-member-v2'
    | 'create-chatroom'
    | 'update-chatroom'
    | 'ack-message'
    | 'start-typing'
    | 'stop-typing'
    | 'pin-chatroom'
    | 'unpin-chatroom'
    | 'redact-message'
    | 'unredact-message'
    | 'delete-chatroom'
    | 'add-multiple-users'
    | 'delete-file-message';

export type SendCommand = {
    type: 'SOCKET/COMMAND',
    transactionId: string,
    chatId: number,
    command: ChatCommand,
    params: Array<*>,
};

export type CommandResult = {
    type: 'SOCKET/RESPONSE',
    transactionId: string,
    result: any,
    error: any,
};

export type CommandMethod = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    ...params: Array<any>
) => void;

export const sendCommand = (
    chatId: number,
    userId: number,
    command: ChatCommand,
    params: Array<*>
): SendCommand => ({
    type: 'SOCKET/COMMAND',
    transactionId: createUuid(userId),
    chatId,
    command,
    params,
});

export const commandResult = (
    transactionId: string,
    result: *,
    error: *
): CommandResult => ({
    type: 'SOCKET/RESPONSE',
    transactionId,
    result,
    error,
});

const getApplicationData = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    requestData: *
): void => {
    const payload = {
        uuid_type: 'v1',
        transaction_uuid: transactionId,
        get_organizations: requestData.getOrganizations,
        get_chatrooms: requestData.getChatrooms,
        get_instant_messages: requestData.getInstantMessages,
        get_chatroom_members: requestData.getChatroomMembers,
        chatroom_member_chatrooms: requestData.chatroomIds,
        instant_message_chatrooms: requestData.chatroomIds,
    };

    socket.emit('get-application-data', payload);
};

const writeMessage = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    textContent: string,
    messageType: number,
    mentionedUsers: Array<number>,
    attachments: Array<PostAttachmentData>,
    replyMessageId: ?number
): void => {
    const payload = {
        uuid_type: 'v1',
        transaction_uuid: transactionId,
        chatroom_id: chatId,
        text_content: textContent,
        chat_type_id: messageType,
        mentioned_users: mentionedUsers,
        attachments,
        replying_to: replyMessageId,
    };
    socket.emit('send-message-v2', payload);
};

const readMessages = (socket: io, chatId: number, memberId: number, transactionId: string, messageIds: Array<number>): void => {
    const payload = {
        uuid_type: 'v1',
        transaction_uuid: transactionId,
        chatroom_id: chatId,
        instant_message_id_list: messageIds,
    };

    socket.emit('read-messages', payload);
};

const clearNotifications = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string
): void => {
    const payload = {
        uuid_type: 'v1',
        transaction_uuid: transactionId,
        chatroom_id: chatId,
    };

    socket.emit('clear-notifications', payload);
};

const createChatroom = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    name: string,
    description: ?string,
    donorId: ?string,
    organId: ?string,
    organization: ?number,
    chatroomType: ChatroomType,
    managed: boolean,
    restrictions: ?boolean
): void => {
    let payload = {};

    if (chatroomType === 'Standard' || chatroomType === 'Research Offer') {
        payload = {
            uuid_type: 'v1',
            transaction_uuid: transactionId,
            chatroom_name: name,
            chatroom_description: description || undefined,
            chatroom_type: chatroomType,
            managed,
        };
    } else if (chatroomType === 'Case' || chatroomType === 'Donor') {
        payload = {
            uuid_type: 'v1',
            transaction_uuid: transactionId,
            chatroom_name: name,
            chatroom_description: description || undefined,
            donor_id: donorId,
            organ_id: organId,
            chatroom_type: chatroomType,
            managed,
        };
    } else if (chatroomType === 'Templated Case') {
        payload = {
            uuid_type: 'v1',
            transaction_uuid: transactionId,
            donor_id: donorId,
            organ_id: organId,
            transplant_center: organization,
            chatroom_type: chatroomType,
            managed,
        };
    } else if (chatroomType === 'Donor Only') {
        payload = {
            uuid_type: 'v1',
            transaction_uuid: transactionId,
            chatroom_name: name,
            chatroom_description: description || undefined,
            donor_id: donorId,
            chatroom_type: chatroomType,
            managed: managed || false,
            restrictions,
        };
    }

    socket.emit('create-chatroom', payload);
};

const addUser = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    email: string,
    phone: string
): void => {
    // use an unsealed object to prevent flow errors in adding either phone or both
    const payload = {};
    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;
    if (phone) {
        payload.phone = phone;
    } else {
        payload.email = email;
    }
    socket.emit('add-user-v2', payload);
};

const addMultipleUsers = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    usersAdded: any[]
): void => {
    // use an unsealed object to prevent flow errors in adding either phone or both
    const payload = {};
    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;
    payload.usersAdded = usersAdded;

    socket.emit('add-multiple-users', payload);
};

const removeMember = (socket: io, chatId: number, memberId: number, transactionId: string, email: string): void => {
    const payload = {
        uuid_type: 'v1',
        transaction_uuid: transactionId,
        chatroom_id: chatId,
        email,
    };

    socket.emit('remove-member-v2', payload);
};

const deleteChatroom = (socket: io, chatId: number, memberId: number, transactionId: string): void => {
    const payload = {
        uuid_type: 'v1',
        transaction_uuid: transactionId,
        chatroom_id: chatId,
    };

    socket.emit('delete-chatroom', payload);
};

const updateChatroom = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    description: string,
    name: string,
    managed: boolean
): void => {
    const payload = {};

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;
    payload.managed = managed;

    if (hasValue(description)) {
        payload.chatroom_description = description;
    }

    if (hasValue(name)) {
        payload.chatroom_name = name;
    }

    socket.emit('update-chatroom', payload);
};

const ackMessage = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    messageId: number,
    symbol: string
): void => {
    const payload = {};

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;
    payload.instant_message_id = messageId;
    payload.symbol = symbol;

    socket.emit('ack-message', payload);
};

const startTyping = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string
): void => {
    const payload = {};

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;

    socket.emit('start-typing', payload);
};

const stopTyping = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string
): void => {
    const payload = {};

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;

    socket.emit('stop-typing', payload);
};

const pinChatroom = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string
): void => {
    const payload = {};

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;

    socket.emit('pin-chatroom', payload);
};

const unpinChatroom = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string
): void => {
    const payload = {};

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;

    socket.emit('unpin-chatroom', payload);
};

const redactMessage = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    messageId: number
): void => {
    const payload = {};

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;
    payload.instant_message_id = messageId;

    socket.emit('redact-message', payload);
};

const unredactMessage = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    messageId: number
): void => {
    const payload = {};

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;
    payload.instant_message_id = messageId;

    socket.emit('unredact-message', payload);
};

const deleteFileMessage = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    messageId: number
): void => {
    const payload = {};

    // eslint-disable-next-line no-console
    console.log('deleteFileMessage', chatId, memberId, transactionId, messageId);

    payload.uuid_type = 'v1';
    payload.transaction_uuid = transactionId;
    payload.chatroom_id = chatId;
    payload.instant_message_id = messageId;

    socket.emit('delete-file-message', payload);
};

const resendNotification = (
    socket: io,
    chatId: number,
    memberId: number,
    transactionId: string,
    userId: number,
    messageId: number
): void => {
    const payload = {
        uuid_type: 'v1',
        transaction_uuid: transactionId,
        user_id: userId,
        instant_message_id: messageId,
    };
    socket.emit('resend-notification', payload);
};

export function* sendMessage(
    chatId: number,
    memberId: number,
    textContent: string,
    messageType: number,
    tmpId: string,
    attachments: Array<PostAttachmentData>,
    mentionedUsers: Array<number>,
    replyMessageId: ?number
): Saga<?CommandResult> {
    const ack = yield call(
        sendCommandAndGetResult,
        chatId,
        memberId,
        'send-message-v2',
        [
            textContent,
            messageType,
            mentionedUsers,
            attachments,
            replyMessageId
        ]
    );

    if (ack && ack.result) {
        // Success, lets update the local chat item
        for (let i = 0; i < ack.result.instant_messages.length; i += 1) {
            const instantMessage = ack.result.instant_messages[i];
            yield put(receiveNewMessage(
                chatId,
                memberId,
                {
                    id: instantMessage.instant_message_id,
                    senderId: memberId,
                    senderName: instantMessage.sender_name,
                    sentTime: instantMessage.sent_time,
                    messageType: instantMessage.chat_type_id,
                    textContent: instantMessage.text_content,
                    dataContent: null,
                    displayName: instantMessage.display_name,
                    fileName: instantMessage.file_name,
                    localPath: null,

                    seenByCount: 0,
                    seenByCurrentUser: true,

                    status: null,
                    ack: {},
                    redactTime: null,
                    replyingMessageId: instantMessage.replying_to,
                    replyingTextContent: instantMessage.replying_to_text_content,
                    replyingMessageType: instantMessage.replying_to_chat_type_id,
                    replyingDataContent: instantMessage.replying_to_data_content,
                    replyingFilePath: instantMessage.replying_to_file_path,
                    replyingSenderId: instantMessage.replying_to_sender_id,
                    parentMessageId: instantMessage.parent_message_id,
                },
                instantMessage.instant_message_id,
                'end',
                {
                    textContent: instantMessage.text_content ? instantMessage.text_content : 'File uploaded',
                    sentTime: instantMessage.sent_time,
                },
                instantMessage.sent_time,
                1
            ));
        }
    } else {
        // Set the status of the message to failed
        yield put(setMessageStatus(chatId, tmpId, 'failed'));
    }
}

function* waitForCommandResult(action: SendCommand): Saga<?CommandResult> {
    const channel = getResultChannel();

    if (channel) {
        // wait for correct result
        while (true) {
            const ack: CommandResult = yield take(channel);

            if (ack.transactionId === action.transactionId) {
                // Attach original command to the error so we can log better errors to sentry
                if (ack.error && !ack.error.requestSocketEvent) {
                    ack.error.requestSocketEvent = action.command;

                    Sentry.captureException(`waitForCommandResult Failed: ${JSON.stringify(ack)}`);
                }
                return ack;
            }

            // If this is not the result we are looking for put it back into the result channel
            devLog('socket-stress', ['waitForCommandResult: System under stress : responses are overlapping ...', ack]);
            yield put(ack);

            // Release cpu resources a bit
            yield delay(32);
        }
    } else if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Failed to get command result channel');
    }

    return undefined;
}

export function* sendCommandAndGetResult(chatId: number, memberId: number, command: ChatCommand, params: Array<*>): Saga<?CommandResult> {
    const cmd = sendCommand(chatId, memberId, command, params);

    yield put(cmd);

    // Increased the timeout on the race condition here to a larger size to help lower connectivity clients
    // and a potentially larger data size being returned, particularly with get-application-data
    const raceResult = yield race({
        commandResult: call(waitForCommandResult, cmd),
        timeout: delay(30000),
    });

    if (raceResult.commandResult) {
        return raceResult.commandResult;
    }

    yield put(triggerChatSocket());

    devLog('queue', ['sendCommandAndGetResult:Timeout', cmd]);

    return commandResult(
        cmd.transactionId,
        undefined,
        {
            error: 'Timed out',
            // Attach original command to the error so we can log better errors to sentry
            requestSocketEvent: command,
        }
    );
}

export function* sendCommandNoResult(chatId: number, memberId: number, command: ChatCommand, params: Array<*>): Saga<void> {
    const cmd = sendCommand(chatId, memberId, command, params);
    devLog('queue', ['sendCommandNoResult', cmd]);

    yield put(cmd);
}

export const getCommandMethod = (command: string): ?CommandMethod => {
    // Map the command to an actual method
    switch (command || '') {
        case 'get-application-data':
            return getApplicationData;

        case 'send-message-v2':
            return writeMessage;

        case 'read-messages':
            return readMessages;

        case 'clear-notifications':
            return clearNotifications;

        case 'add-user-v2':
            return addUser;

        case 'add-multiple-users':
            return addMultipleUsers;

        case 'remove-member-v2':
            return removeMember;

        case 'create-chatroom':
            return createChatroom;

        case 'update-chatroom':
            return updateChatroom;

        case 'ack-message':
            return ackMessage;

        case 'start-typing':
            return startTyping;

        case 'stop-typing':
            return stopTyping;

        case 'pin-chatroom':
            return pinChatroom;

        case 'unpin-chatroom':
            return unpinChatroom;

        case 'redact-message':
            return redactMessage;

        case 'unredact-message':
            return unredactMessage;

        case 'delete-file-message':
            return deleteFileMessage;

        case 'resend-notification':
            return resendNotification;

        case 'delete-chatroom':
            return deleteChatroom;

        default:
            break;
    }

    return undefined;
};
