// @flow
/* eslint-disable max-len */
import React, { Component } from 'react';
import Avatar from 'react-toolbox/lib/avatar';
import Chip from 'react-toolbox/lib/chip';
import { connect } from 'react-redux';
import ChatListStyles from './Styles/ChatListStyles';
import type { Tag } from '../Utils/types';
import { getOrganIcon, getTagIcon } from '../Utils/getTagIcon';
import { getTextColorBasedOnBgColor } from '../Utils/tags';

type Props = {
    entity: 'case' | 'chat' | 'caseItem';
    highRisk: boolean;
    organType: string | null;
    tags: Array<Tag>;
    availableTags: Array<Tag>,
};

class CaseTagDrawer extends Component<Props> {
    constructor(props) {
        super(props);
        this.tagRefs = (props.tags || []).reduce((acc, tag) => {
            acc[tag.label] = React.createRef();
            return acc;
        }, {});
    }

    componentDidMount() {
        this.checkLabelOverflow();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tags !== this.props.tags) {
            this.tagRefs = (this.props.tags || []).reduce((acc, tag) => {
                acc[tag.label] = React.createRef();
                return acc;
            }, {});
            this.checkLabelOverflow();
        }
    }

    tagRefs: { [string]: any };

    checkLabelOverflow() {
        Object.keys(this.tagRefs).forEach((key) => {
            const { current, } = this.tagRefs[key];
            if (current) {
                const isOverflowing = current.offsetWidth < current.scrollWidth;
                if (isOverflowing) {
                    current.classList.add('scrolling-tag');
                } else if (current.classList) {
                    current.classList.remove('scrolling-tag');
                }
            }
        });
    }

    // create visual from tags
    createTag = (tag: Tag) => {
        const {
            category,
            label,
            displayColors,
        } = tag;

        // use black text for lighter backgrounds
        const textLabelColor = getTextColorBasedOnBgColor(displayColors[0], '#FFFFFF', '#000000');
        let tagIcon = null;

        if (category === 'Organs') {
            tagIcon = getOrganIcon(label);
        } else {
            tagIcon = getTagIcon(label);
        }

        return (
            <Chip
                key={`tag-${label}`}
                style={ChatListStyles.caseTagItem}
            >
                <Avatar
                    style={{ ...ChatListStyles.organTagAvatar, ...{ backgroundColor: displayColors[0], color: displayColors[1] ? displayColors[1] : textLabelColor, }, }}
                >
                    {tagIcon}
                </Avatar>
                <span>{label}</span>
            </Chip>
        );
    }

    createCaseTag = (tag: Tag) => {
        const {
            category,
            label,
            displayColors,
        } = tag;

        // use black text for lighter backgrounds
        const textLabelColor = getTextColorBasedOnBgColor(displayColors[0], '#FFFFFF', '#000000');
        let tagIcon = null;

        if (category === 'Organs') {
            tagIcon = getOrganIcon(label);
        } else {
            tagIcon = getTagIcon(label);
        }

        // set whether a tag will scroll on hover or not based on the length of the tag label
        if (!this.tagRefs[label]) {
            this.tagRefs[label] = React.createRef();
        }

        return (
            <div className="case-tag-item" key={label}>
                <div
                    className="tag-icon-container"
                    style={{
                        ...ChatListStyles.organTagAvatar,
                        ...{
                            height: 'auto',
                            backgroundColor: displayColors[0],
                            color: displayColors[1] ? displayColors[1] : textLabelColor,
                        },
                    }}
                >
                    {tagIcon}
                </div>
                <div ref={this.tagRefs[label]} style={ChatListStyles.caseTagLabel}>{label}</div>
            </div>
        );
    }

    createHighRiskTag = () => {
        const {
            highRisk,
            entity,
        } = this.props;

        if (highRisk && entity === 'caseItem') {
            return (
                <div className="case-tag-item" key="case-high-risk">
                    <div
                        className="tag-icon-container"
                    >
                        <Avatar style={{ ...ChatListStyles.highRiskAvatar, ...{ borderRadius: 0, }, }} icon="flag" />
                    </div>
                    <div style={{ ...ChatListStyles.caseTagLabel, ...ChatListStyles.highRiskTag, }}>Risk Criteria</div>
                </div>
            );
        }

        if (highRisk) {
            return (
                <Chip
                    key="high-risk"
                    style={{ ...ChatListStyles.caseTagItem, ...ChatListStyles.highRiskTag, }}
                >
                    <Avatar style={ChatListStyles.highRiskAvatar} icon="flag" />
                    <span>Risk Criteria</span>
                </Chip>
            );
        }
        return null;
    }

    createChatTag = () => {
        const {
            organType,
            availableTags,
        } = this.props;
        const allChips = [];

        const tagMatch = availableTags ? availableTags.find((tag) => tag.label === organType) : false;
        if (tagMatch) {
            const chip = this.createTag(tagMatch);
            allChips.push(chip);
        }

        return (
            <div>
                {allChips}
            </div>
        );
    }

    renderCaseItemTags = () => {
        const {
            tags,
        } = this.props;

        // flip the order of the tags so that the most recent tags are shown first
        const reversedTags = tags.slice().reverse();

        const allChips = [];
        allChips.push(this.createHighRiskTag());
        reversedTags.forEach((tag) => {
            const chip = this.createCaseTag(tag);
            allChips.push(chip);
        });

        return (
            allChips.length ? (
                <div style={ChatListStyles.caseTagContainer}>
                    {allChips}
                </div>
            ) : null
        );
    }

    render() {
        const {
            tags,
            entity,
        } = this.props;

        const allChips = [];

        if (entity === 'chat') {
            return this.createChatTag();
        } if (entity === 'caseItem') {
            return this.renderCaseItemTags();
        }

        allChips.push(this.createHighRiskTag());
        tags.forEach((tag) => {
            const chip = this.createTag(tag);
            allChips.push(chip);
        });

        return (
            allChips.length ? (
                <div style={ChatListStyles.caseTagContainer}>
                    {allChips}
                </div>
            ) : null
        );
    }
}

function mapStateToProps(state) {
    return {
        availableTags: state.donor.availableTags,
    };
}

export default connect(mapStateToProps, {})(CaseTagDrawer);
