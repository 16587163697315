// @flow
import React, { useState, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import sift from 'sift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSort,
    faSortDown,
    faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import IconMenu from 'react-toolbox/lib/menu/IconMenu';
import MenuItem from 'react-toolbox/lib/menu/MenuItem';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import LoadingIndicator from 'react-loading-indicator';
import ReactTooltip from 'react-tooltip';
import {
    Checkbox, FormControlLabel, FormHelperText, FormControl,
} from '@material-ui/core';
import {
    updateProfile as _updateProfile,
} from 'txp-core';

import Search from './Search';
import ChatListStyles from './Styles/ChatListStyles';
import type {
    CaseRow,
    Donor,
    DonorFilter,
    DonorSortBy,
    EventName,
    Tag,
    UserProfile,
} from '../Utils/types';
import {
    loadDonors as _loadDonors,
    updateTagFilter as _updateTagFilter,
    updateWorkflowTypeFilter as _updateWorkflowTypeFilter,
    setSort as _setSort,
} from '../Redux/DonorActions';
import {
    logFirebaseEvent as _logFirebaseEvent,
} from '../Redux/ApplicationActions';
import { getFilteredSortedDonorsSelector } from '../Selectors/Donor';
import DonorListItem from './DonorListItem';
import { useWorkflowOptions } from '../Utils/hooks';

type Props = {
    // Passed props
    filterDonors: (input: string) => *,

    onShowDonorPanel: () => *,

    // Connected props
    profile: UserProfile,
    donorFilter: DonorFilter,
    sort: { column: DonorSortBy, direction: SortDirection },
    loadingApplicationData: boolean,
    loadingSearch: boolean,
    loadingDonors: boolean,
    donorQuery: string,
    openDonorId: ?number,
    availableTags: Array<Tag>,
    filterTags: string[],
    allDonors: Array<Donor>,
    workflowTypeFilters: string[],

    loadDonors: (filterStatus: ?string) => *,
    updateProfile: (user: UserProfile) => *,
    setSort: typeof _setSort,
    logFirebaseEvent: (name: EventName) => *,
    updateTagFilter: (tags: string[]) => *,
    updateWorkflowTypeFilter: (workflowTypes: string[]) => *,
};

const DonorList = ({
    profile,
    donorFilter,
    filterTags,
    workflowTypeFilters,
    loadDonors,
    updateProfile,
    sort,
    setSort,
    logFirebaseEvent,
    updateTagFilter,
    updateWorkflowTypeFilter,
    donorQuery,
    openDonorId,
    filterDonors,
    onShowDonorPanel,
    availableTags,
    allDonors,
    loadingApplicationData,
    loadingSearch,
    loadingDonors,
}: Props) => {
    const donorSortBy = sort.column;
    const donorSortDirection = sort.direction;
    const [donorFilterValue, setDonorFilterValue] = useState(donorFilter
        ? { ...donorFilter, filterTags, workflowTypeFilters, }
        : {
            status: 'Open', donorType: 'All', filterTags, workflowTypeFilters,
        });
    const [filterTagsValue, setFilterTagsValue] = useState(filterTags);
    const [filterTagsMenuActive, setFilterTagsMenuActive] = useState(false);
    const [workflowTypeFiltersValue, setWorkflowTypeFiltersValue] = useState(workflowTypeFilters);

    const { data: workflowOptions = [], } = useWorkflowOptions();
    const donors = useSelector((state) => getFilteredSortedDonorsSelector(state, workflowOptions));

    useEffect(() => {
        loadDonors();
    }, [loadDonors]);

    const workflowTypeFilter = useMemo(() => {
        // NOTE: Workflows are now built with the Workflow Builder, which allows for updating existing workflows. In those
        //     : cases, the Workflow key is automatically appending with a '-v<d>' suffix to indicate the current version
        //     : of the workflow and associate a lineage from the parent workflow. When we are filtering the cases by
        //     : Workflow, the user really only cares what the name of the workflow appears as - to achieve that we trim
        //     : the version identifier from the key and match on the non-versioned part of the key. Ideally here we could
        //     : just use the 'Workflow Name', however the Donor object only contains the key, so we perpetuate the trimmed
        //     : key throughout to filter on
        const unversionedWorkflowOptions = workflowOptions.map((workflow) => ({
            label: workflow.name,
            value: workflow.key.replace(/-v\d+$/, ''),
        }));
        const uniqueWorkflowTypes = new Map();

        // Find workflows for the current list of donors
        // NOTE: These options may need to be provided by the server eventually
        //     : if we move to server-paginated results
        allDonors.forEach((donor) => {
            const workflowKey = donor.workflow?.replace(/-v\d+$/, '');
            const option = unversionedWorkflowOptions.find((opt) => opt.value === workflowKey);

            if (option && !uniqueWorkflowTypes.has(option.value)) {
                uniqueWorkflowTypes.set(option.value, option);
            }
        });

        return Array.from(uniqueWorkflowTypes.values());
    }, [allDonors, workflowOptions]);

    const onSelectDonorSortBy = (sortBy: DonorSortBy) => {
        const sortDirection = sortBy === donorSortBy
            ? (donorSortDirection === 'asc' ? 'desc' : 'asc')
            : 'desc';

        setSort(sortBy, sortDirection);
    };

    const onSelectStatusFilter = (statusFilter: string) => {
        // don't make updateProfile call if they chose the same sort
        if (statusFilter !== donorFilterValue.status) {
            const newDonorFilter = { status: statusFilter, donorType: donorFilterValue.donorType, };
            const newProfile = { ...profile, donorFilter: newDonorFilter, };
            updateProfile(newProfile);
            setDonorFilterValue(newDonorFilter);

            // Load Donors again based on new filter
            if (filterIncludesClosedCases(donorFilterValue.status) !== filterIncludesClosedCases(statusFilter)) {
                loadDonors(statusFilter);
            }
        }
    };

    const onSelectTagFilter = (filterTagId: string) => {
        const tempTagFilter: string[] = filterTagsValue;
        if (tempTagFilter.includes(filterTagId)) {
            const idx = tempTagFilter.indexOf(filterTagId);
            tempTagFilter.splice(idx, 1);
        } else {
            tempTagFilter.push(filterTagId);
        }
        updateTagFilter(tempTagFilter);
        setFilterTagsValue(tempTagFilter);
    };

    const onSelectWorkflowFilter = (filter: string) => {
        const tempWorkflowTypeFilter: string[] = workflowTypeFiltersValue;
        if (tempWorkflowTypeFilter.includes(filter)) {
            const idx = tempWorkflowTypeFilter.indexOf(filter);
            tempWorkflowTypeFilter.splice(idx, 1);
        } else {
            tempWorkflowTypeFilter.push(filter);
        }
        updateWorkflowTypeFilter(tempWorkflowTypeFilter);
        setWorkflowTypeFiltersValue(tempWorkflowTypeFilter);
    };

    const onFilterMenuClick = (event: any) => {
        event.stopPropagation();
        ReactTooltip.hide();
        if (event.target.innerText === 'filter_list') {
            logFirebaseEvent('filter-menu-click');
        }
    };

    const renderDonors = () => {
        const normalizedQuery = donorQuery.toUpperCase();

        const donorItems = donorQuery ? donors.filter(
            sift({
                $where(donor) {
                    return (donor.name?.toUpperCase().includes(normalizedQuery))
                        || (donor.unosId?.toUpperCase().includes(normalizedQuery))
                        || (donor.sex?.toUpperCase().includes(normalizedQuery))
                        // Note: we are no longer displaying the location in the donor list, should we continue to search on it?
                        || (donor.location?.toUpperCase().includes(normalizedQuery))
                        || (donor.age?.toString().includes(normalizedQuery))
                        || (donor.lastNote?.note.toUpperCase().includes(normalizedQuery))
                        || (donor.tags?.some((tag) => tag.label.toUpperCase().includes(normalizedQuery)));
                },
            })
        ) : donors;

        const noDonorSiftResults = donorItems.length === 0 && donors.length !== 0;

        const statusFilter = [
            {
                label: 'Open cases',
                value: 'Open',
            },
            {
                label: 'Closed cases',
                value: 'Closed',
            },
            {
                label: 'All cases',
                value: 'All',
            },
            {
                label: 'Risk criteria cases',
                value: 'HighRisk',
            },
            {
                label: 'Normal-risk cases',
                value: 'Normal',
            }
        ];

        const getTagFilterOptions = () => {
            const uniqueTagIds: Set<number> = new Set();

            allDonors.forEach((donor) => {
                (donor.tags || []).forEach((tag) => {
                    uniqueTagIds.add(tag.tagId);
                });
            });

            const inUseTags: Tag[] = availableTags.filter((tag) => Array.from(uniqueTagIds).includes(tag.tagId));
            const inUseTagsFilterOptions = [];
            inUseTags.forEach((tag: Tag) => {
                inUseTagsFilterOptions.push({
                    label: tag.label,
                    value: tag.tagId.toString(),
                });
            });
            return inUseTagsFilterOptions;
        };

        const tagFilter = getTagFilterOptions();

        return (
            <div>
                <div style={{ ...ChatListStyles.caseSearchBlock, zIndex: 0, }}>
                    <div style={ChatListStyles.searchRowWithSort}>
                        <Search
                            placeholder="Search cases..."
                            value={donorQuery}
                            filterContent={filterDonors}
                        />
                        <div style={ChatListStyles.sortContainer}>
                            <IconMenu
                                active={filterTagsMenuActive}
                                onShow={() => setFilterTagsMenuActive(true)}
                                onHide={() => setFilterTagsMenuActive(false)}
                                icon="tune"
                                position="topRight"
                                menuRipple={false}
                                onClick={(event) => onFilterMenuClick(event)}
                                data-tip="Filter cases"
                                data-delay-show="250"
                                data-effect="solid"
                                data-place="left"
                            >
                                <MenuItem
                                    caption="Filter cases"
                                    disabled
                                />
                                <MenuDivider />
                                {
                                    statusFilter.map((option: any) => (
                                        <MenuItem
                                            key={option.value}
                                            style={donorFilterValue.status === option.value ? ChatListStyles.selectedSort : null}
                                            caption={option.label}
                                            ripple={false}
                                            onClick={() => onSelectStatusFilter(option.value)}
                                        />
                                    ))
                                }
                                <MenuDivider />
                                <MenuItem
                                    caption="By Tags"
                                    disabled
                                />
                                <FormControl disabled={false}>
                                    <div style={ChatListStyles.filterOption}>
                                        <FormHelperText>Add tag filters</FormHelperText>
                                        {
                                            tagFilter.map((option: any) => (
                                                <FormControlLabel
                                                    key={`tagFilterOption-${option.value}`}
                                                    control={(
                                                        <Checkbox
                                                            checked={filterTagsValue.includes(option.value)}
                                                            onChange={() => onSelectTagFilter(option.value)}
                                                            name="tagFilter"
                                                            color="secondary"
                                                            value={option.value}
                                                        />
                                                    )}
                                                    label={option.label}
                                                />
                                            ))
                                        }
                                    </div>
                                </FormControl>
                                <MenuDivider />
                                <MenuItem
                                    caption="By Workflow"
                                    disabled
                                />
                                <FormControl disabled={false}>
                                    <div style={ChatListStyles.filterOption}>
                                        {
                                            workflowTypeFilter.map((option: any) => (
                                                <FormControlLabel
                                                    key={`workflowTypeFilter-${option.value}`}
                                                    control={(
                                                        <Checkbox
                                                            checked={workflowTypeFiltersValue.includes(option.value)}
                                                            name="workflowTypeFilter"
                                                            color="secondary"
                                                            value={option.value}
                                                            onChange={() => onSelectWorkflowFilter(option.value)}
                                                        />
                                                    )}
                                                    label={option.label}
                                                />
                                            ))
                                        }
                                    </div>
                                </FormControl>
                            </IconMenu>
                        </div>
                    </div>
                </div>
                <div style={{ paddingLeft: '10px', paddingInline: '10px', }}>
                    <table className="case-table">
                        <thead>
                            <tr>
                                <TH
                                    sortable
                                    col="NAME"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('NAME')}
                                >
                                    Case
                                </TH>
                                <TH
                                    sortable
                                    col="CREATE_DATE"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('CREATE_DATE')}
                                >
                                    Created
                                </TH>
                                <th>Tags</th>
                                <TH
                                    sortable
                                    col="LAST_ACTIVITY"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('LAST_ACTIVITY')}
                                >
                                    Last Activity
                                </TH>
                                <TH
                                    sortable
                                    col="NEXT_DUE_DATE"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('NEXT_DUE_DATE')}
                                >
                                    Next Due Date
                                </TH>
                                <TH
                                    sortable
                                    col="PROGRESS"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('PROGRESS')}
                                >
                                    Case Progress
                                </TH>
                                <TH
                                    sortable
                                    col="NOTES"
                                    sortKey={donorSortBy}
                                    sortDirection={donorSortDirection}
                                    onClick={() => onSelectDonorSortBy('NOTES')}
                                >
                                    Case Note
                                </TH>
                            </tr>
                        </thead>
                        <tbody>
                            {donorItems.map((row: CaseRow) => (row && row.donorId
                                ? (
                                    <DonorListItem
                                        row={row}
                                        key={row.donorId}
                                        onShowDonorPanel={onShowDonorPanel}
                                        selected={row.donorId === openDonorId}
                                    />
                                ) : null))}
                            {noDonorSiftResults
                                ? (
                                    <tr>
                                        <td
                                            colSpan={7}
                                            style={{ textAlign: 'center', }}
                                        >
                                            <span style={ChatListStyles.noChatroomsFound}>
                                                No donors found matching the query.
                                            </span>
                                        </td>
                                    </tr>
                                ) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <div style={{ ...ChatListStyles.wrapper, ...ChatListStyles.borderRight, }}>
            {loadingApplicationData
                ? (
                    <div style={ChatListStyles.loadingIndicator}>
                        <LoadingIndicator />
                        <div style={ChatListStyles.smallLeftMargin}>
                            Loading Data...
                        </div>
                    </div>
                ) : (
                    <div>
                        <ReactTooltip />
                        <div>
                            {loadingSearch || loadingDonors
                                ? (
                                    <div style={ChatListStyles.loadingIndicator}>
                                        <LoadingIndicator />
                                        <div style={ChatListStyles.smallLeftMargin}>
                                            Loading Cases...
                                        </div>
                                    </div>
                                ) : (renderDonors())}
                        </div>
                    </div>
                )}
        </div>
    );
};

const defaultSort = {
    column: 'CREATE_DATE',
    direction: 'desc',
};

const mapStateToProps = (state) => {
    const { profile, } = state.auth;

    return {
        profile,
        openDonorId: state.donor.openDonorId,
        sort: state.donor?.sort ?? defaultSort,
        donorFilter: profile.donorFilter,
        loadingApplicationData: state.loading.applicationData,
        loadingSearch: state.loading.search,
        loadingDonors: state.loading.donors,
        donorQuery: state.donor.filter,
        availableTags: state.donor.availableTags,
        filterTags: state.donor.caseTagFilter,
        workflowTypeFilters: state.donor.workflowTypes,
        allDonors: state.donor.donors ? Object.values(state.donor.donors) : [],
    };
};

export default connect(mapStateToProps, {
    updateProfile: _updateProfile,
    loadDonors: _loadDonors,
    logFirebaseEvent: _logFirebaseEvent,
    updateTagFilter: _updateTagFilter,
    updateWorkflowTypeFilter: _updateWorkflowTypeFilter,
    setSort: _setSort,
})(DonorList);

type SortDirection = 'asc' | 'desc';

/* eslint-disable react/require-default-props */
type THProps = {
    children: React$Node;
    sortable: boolean;
    col?: string;
    sortKey?: string;
    sortDirection?: SortDirection;
    onClick?: () => *,
};

function TH({
    sortable = false,
    col = '',
    sortKey = '',
    sortDirection = 'desc',
    onClick,
    children,
}: THProps) {
    const activelySorting = sortable && col === sortKey;
    const classes = `${sortable ? 'sortable' : ''} ${activelySorting ? `sorted sorted-${sortDirection}` : ''}`;
    const faSortIcon = activelySorting ? (sortDirection === 'asc' ? faSortUp : faSortDown) : faSort;

    return (
        <th
            className={classes}
            onClick={onClick}
        >
            {children}
            {sortable ? <FontAwesomeIcon icon={faSortIcon} /> : null}
        </th>
    );
}

const filtersWithClosedCases = ['Closed', 'All'];

function filterIncludesClosedCases(status: string) {
    return filtersWithClosedCases.includes(status);
}
