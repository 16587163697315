// @flow
import Colors from '../../Themes/Colors';

const CaseListStyles = {
    caseRow: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.caseListItemBorder,
        borderBottomStyle: 'solid',
        outline: 'none',
        cursor: 'pointer',
        zIndex: '-10',
        // height: 'auto',
        // minHeight: '100px',
        maxHeight: '150px',
        minHeight: '60px',
    },
    caseRowSelected: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.caseListItemBorder,
        borderBottomStyle: 'solid',
        backgroundColor: Colors.lighterBlue,
        outline: 'none',
        cursor: 'pointer',
        zIndex: '-10',
        minHeight: '60px',
        maxHeight: '150',
    },
};

export default CaseListStyles;
