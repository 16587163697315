// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId, selectProfileName } from 'txp-core';
import UserDetails from './UserDetails';
import FollowerExternalMemberItem from './FollowerExternalMemberItem';
import Images from '../Themes/Images';

import {
    setPendingRemovedFollowers,
} from '../Redux/FollowerEditActions';
import type {
    AvatarMap,
    UserProfile,
} from '../Utils/types';
import FollowerGroupStyles from './Styles/FollowerGroupStyles';

type Props = {
    memberUsers: Array<UserProfile>,
    externalUsers: Array<any>,
    canUpdateDonor: boolean,
    avatars: AvatarMap,
    onShowAddPeopleClick: () => *,
    onJoinGroupClick: () => *,
};

export default function FollowerMemberList(props: Props) {
    const {
        memberUsers,
        externalUsers,
        avatars,
        canUpdateDonor,
        onShowAddPeopleClick,
        onJoinGroupClick,
    } = props;

    const memberId = useSelector((state) => selectUserId(state.auth));
    const isMemberInGroup = memberUsers.some((user) => user.userId === memberId);
    const pendingRemovedUsers = useSelector((state) => state.followerEdit.pendingRemovedUsers);
    const pendingUsers = useSelector((state) => state.followerEdit.pendingUsers);

    const dispatch = useDispatch();

    const onRemoveMemberUser = (removedId: number, followerMemberName: string) => {
        const updatedRemovedUsers = pendingRemovedUsers.concat({
            memberId: removedId,
            isExternalUser: false,
            displayName: followerMemberName,
        });

        dispatch(setPendingRemovedFollowers(updatedRemovedUsers));
    };

    const onRemoveExternalUser = (removedId: number, followerMemberName: string) => {
        const updatedRemovedUsers = pendingRemovedUsers.concat({
            memberId: removedId,
            isExternalUser: true,
            displayName: followerMemberName,
        });

        dispatch(setPendingRemovedFollowers(updatedRemovedUsers));
    };

    const renderInternalMember = (member: UserProfile) => {
        const avatar = (avatars[member.userId] || Images.circledDefaultProfile);
        const displayName = selectProfileName(member, member.email);
        const pendingRemovedMemberIds = pendingRemovedUsers
            .filter((removedMember) => !removedMember.isExternalUser)
            .map((removedMember) => removedMember.memberId);
        const isPendingRemoved = pendingRemovedMemberIds.includes(member.userId);
        return (
            !isPendingRemoved
                ? (
                    <UserDetails
                        key={member.userId}
                        membershipStatus="Present"
                        startDate=""
                        endDate=""
                        userProfile={member}
                        avatar={avatar}
                        displayButton={canUpdateDonor}
                        doNotDisturb={false}
                        displayEmail
                        wrapEmail={false}
                        isConnected
                        onMemberClick={() => onRemoveMemberUser(member.userId, displayName)}
                        // Not relevant to follower members
                        addManager={() => {}}
                        removeManager={() => {}}
                        showMemberProfile={() => {}}
                        isTeamList
                    />
                ) : null
        );
    };

    const renderExternalMember = (externalUser) => {
        const pendingRemovedExternalIds = pendingRemovedUsers
            .filter((removedMember) => removedMember.isExternalUser)
            .map((removedMember) => removedMember.memberId);
        const isPendingRemoved = pendingRemovedExternalIds.includes(externalUser.userId);
        return (
            !isPendingRemoved
                ? (
                    <FollowerExternalMemberItem
                        key={externalUser.userId}
                        externalUser={externalUser}
                        onRemoveMember={() => onRemoveExternalUser(externalUser.userId, externalUser.fullName)}
                        canUpdate={canUpdateDonor}
                        // TODO: Tie this to loading actions or remove
                        isLoading={false}
                    />
                ) : null
        );
    };

    const renderExternalMemberSection = () => {
        const textMessageUsers = externalUsers.filter((externalUser) => externalUser.phone);
        const emailAlertUsers = externalUsers.filter((externalUser) => !externalUser.phone && externalUser.email);
        return (
            <div>
                {renderSectionHeader('Text Message Alerts')}
                {textMessageUsers.length > 0 ? (
                    textMessageUsers.map((memberUser) => renderExternalMember(memberUser))
                ) : (
                    renderEmptyList('No users receive text alerts')
                )}

                {renderSectionHeader('Email Alerts')}
                {emailAlertUsers.length > 0 ? (
                    emailAlertUsers.map((memberUser) => renderExternalMember(memberUser))
                ) : (
                    renderEmptyList('No users receive email alerts')
                )}
            </div>
        );
    };

    const renderSectionHeader = (title: string) => (
        <div
            style={FollowerGroupStyles.sectionHeader}
        >
            <strong>{title}</strong>
        </div>
    );

    const renderEmptyList = (text: string) => (
        <div style={FollowerGroupStyles.emptyList}>
            {text}
        </div>
    );

    const renderInternalMemberSection = () => (
        <div>
            {renderSectionHeader('OmniLife Users')}
            {memberUsers.length > 0 ? (
                memberUsers.map((memberUser) => renderInternalMember(memberUser))
            ) : (
                renderEmptyList('No OmniLife members to display')
            )}
        </div>
    );

    const renderPendingMemberSection = () => {
        if (pendingUsers.length === 0) {
            return null;
        }
        const text = pendingUsers.map((user) => selectProfileName(user)).join(', ');
        return (
            <div>
                {renderSectionHeader('Members to Add')}
                <div style={FollowerGroupStyles.sectionText}>{text}</div>
            </div>
        );
    };

    const renderPendingRemovedSection = () => {
        if (pendingRemovedUsers.length === 0) {
            return null;
        }
        const text = pendingRemovedUsers.map((user) => user.displayName).join(', ');
        return (
            <div>
                {renderSectionHeader('Members to Remove')}
                <div style={FollowerGroupStyles.sectionText}>{text}</div>
            </div>
        );
    };

    return (
        <div>
            <div
                style={canUpdateDonor ? FollowerGroupStyles.commandArea : FollowerGroupStyles.commandAreaDisabled}
                className="lineButtons"
                role="button"
                tabIndex="0"
                onKeyDown={canUpdateDonor ? onShowAddPeopleClick : null}
                onClick={canUpdateDonor ? onShowAddPeopleClick : null}
                data-tip="Add users to this donor"
                data-delay-show="500"
                data-effect="solid"
            >
                Add people
            </div>
            <div
                style={canUpdateDonor && !isMemberInGroup ? FollowerGroupStyles.commandArea : FollowerGroupStyles.commandAreaDisabled}
                className="lineButtons"
                role="button"
                tabIndex="0"
                onKeyDown={canUpdateDonor && !isMemberInGroup ? onJoinGroupClick : null}
                onClick={canUpdateDonor && !isMemberInGroup ? onJoinGroupClick : null}
            >
                Join Group
            </div>
            <div style={FollowerGroupStyles.sectionContainer}>
                {renderPendingMemberSection()}
                {renderPendingRemovedSection()}
                {renderInternalMemberSection()}
                {renderExternalMemberSection()}
            </div>
        </div>
    );
}
