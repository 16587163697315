// @flow
import React, { PureComponent } from 'react';
import Switch from 'react-toolbox/lib/switch/Switch';
import { List, ListDivider } from 'react-toolbox/lib/list';
import RadioButton from 'react-toolbox/lib/radio/RadioButton';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import ReactTooltip from 'react-tooltip';
import LoadingIndicator from 'react-loading-indicator';
import { connect } from 'react-redux';
import type { UserProfile } from 'txp-core';
import { selectProfileEmail, selectProfileName, distanceToNow } from 'txp-core';

import UserDetails from './UserDetails';
import AddPeople from './AddPeople';
import ChatFileItem from './ChatFileItem';
import TabSelector from './TabSelector';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';
import AuthStyles from '../Containers/Styles/AuthStyles';
import {
    startEdit as _startEdit,
    saveChat as _saveChat,
} from '../Redux/ChatEditActions';
import { setSelectedFile as _setSelectedFile } from '../Redux/ChatListActions';
import type { ChatEditState } from '../Redux/ChatEditActions';
import type { ChatroomState } from '../Redux/ChatroomActions';
import {
    openDonor as _openDonor,
    getDonor as _getDonor,
} from '../Redux/DonorActions';
import {
    setSagaMessage as _setSagaMessage,
    logFirebaseEvent as _logFirebaseEvent,
} from '../Redux/ApplicationActions';
import {
    addPermission as _addPermission,
    removePermission as _removePermission,
} from '../Redux/PermissionActions';
import { keys } from '../Utils/Object';
import { sounds, NotificationSoundMap } from '../Themes/Sounds';
import truncate, { transformCaseName } from '../Utils/text';
import Images from '../Themes/Images';
import type {
    AvatarMap,
    ChatroomInfo,
    ChatroomMemberMap,
    Donor,
    EventName,
    ChatroomFileMap,
    ChatroomFile,
    MessageId,
    ChatroomMember,
    ResourcePermission,
    UserPermission,
    Team,
} from '../Utils/types';
import ChatListStyles from './Styles/ChatListStyles';
import BackButton from './BackButton';
import hasPermissions, {
    CHATROOM_CREATE,
    ENTITY_TYPE_CHATROOM,
    ENTITY_TYPE_UI,
    ENTITY_TYPE_DONOR,
    hasResourcePermissions, NONE, READ,
    SUB_ENTITY_TYPE_MANAGER,
    SUB_ENTITY_TYPE_DETAILS,
    UPDATE,
} from '../Utils/hasPermissions';

type Props = {
    user: UserProfile,
    room: ChatroomInfo,
    teams: Array<Team>,
    teamsLoading: boolean,
    avatars: AvatarMap,
    editData: ChatEditState,
    isFileViewVisible: boolean,
    isAddPeopleVisible: boolean,
    isNotificationSoundsVisible: boolean,
    chatroomState: ChatroomState,
    peopleFilter: string,
    files: ChatroomFileMap,
    allFiles: Array<MessageId>,
    myFiles: Array<MessageId>,
    selectedFile: ChatroomFile,
    filesLoading: boolean,
    currentChatId: number,
    accessToken: string,
    verifiedOrgMember: boolean,
    isConnected: boolean,
    permissions: Array<UserPermission>,
    resourcePermissions: Array<ResourcePermission>,
    donors: Array<Donor>,

    onShowFiles: () => *,
    onHideFiles: () => *,
    onShowAddPeople: () => *,
    onHideAddPeople: () => *,
    onShowNotificationSounds: () => *,
    onHideNotificationSounds: () => *,
    onShowDonorDetails: () => *,
    setNotificationSound: (chatId: number, sound: string) => *,
    filterPeople: (filter: string) => *,
    addUsersToChatroom: () => *,
    removeUserFromChatroom: (email: string, self: boolean) => *,
    removeSelfFromFollowerGroup: (donorId: number, followerId: number) => *,
    deleteChatroom: () => *,
    inviteExistingUser: (email: string, phone: string) => *,
    inviteNewUser: (email: string, phone: string) => *,
    clearMessage: () => *,
    setDescription: (description: string) => *,
    setName: (name: string) => *,
    toggleMute: (chatroomId: number) => *,
    onSubmitEdit: () => *,
    startEdit: (
        id: ?number,
        name: string,
        people: ChatroomMemberMap,
        managed: boolean,
        message: string,
        description: ?string,
    ) => *,
    saveChat: (id: ?number, name: string, people: Array<UserProfile>, managed: boolean, message: ?string, description: ?string) => *,
    setSelectedFile: (id: number, file: ChatroomFile) => *,
    showMemberProfile: (selectedChatroomMember: ChatroomMember) => *,
    addPermission: (
        entityType: string,
        entityId: number,
        subEntityType: ?string,
        subEntityId: ?number,
        cascade: boolean,
        read: boolean,
        update: boolean,
        canDelete: boolean,
        authorizedType: string,
        authorizedId: number,
        displayName: string,
        displayToast: boolean,
    ) => *,
    removePermission: (entityType: string, entityId: number, permissionId: number) => *,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,
    logFirebaseEvent: (name: EventName) => *,
    openDonor: (donorId: number) => *,
    getDonor: (donorId: number) => *,
    closeChatroomDetails: () => *,
};

type State = {
    showUpdateButton: boolean,
    selectedSound: string,
    showAllFiles: boolean,
    fullMediaView: boolean,
    isDialogOpen: boolean,
};

class ChatroomDetails extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            showUpdateButton: false,
            selectedSound: this.props.room.notificationSound,
            showAllFiles: true,
            fullMediaView: false,
            isDialogOpen: false,
        };
    }

    componentDidMount() {
        const {
            donors,
            getDonor,
            room,
        } = this.props;

        // If the room has a donorId that hasn't been loaded, load the donors
        if (room.donorId && !donors[room.donorId]) {
            getDonor(room.donorId);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            editData,
            startEdit,
            isFileViewVisible,
        } = this.props;

        const {
            fullMediaView,
        } = this.state;

        if (editData.name !== prevProps.editData.name || editData.description !== prevProps.editData.description) {
            startEdit(
                editData.id,
                editData.name,
                editData.people,
                editData.managed,
                '',
                editData.description
            );
        }

        if (!isFileViewVisible && fullMediaView) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                fullMediaView: false,
            });
        }
    }

    onLeaveGroup = () => {
        const {
            removeUserFromChatroom,
            removeSelfFromFollowerGroup,
            user,
            room,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('leave-group-click');

        const response = window.confirm(`To rejoin ${room.name} you have to be re-invited. Are you sure you wish to leave?`);
        const isFollowerChatroom = room && room.chatroomType && room.chatroomType === 'Follower Group';

        if (response) {
            if (isFollowerChatroom && room.followerId && room.followerId > 0 && room.donorId && room.donorId > 0) {
                removeSelfFromFollowerGroup(room.donorId, room.followerId);
            } else {
                removeUserFromChatroom(user.email, true);
            }
        }
    };

    onDeleteChatroom = () => {
        const {
            deleteChatroom,
        } = this.props;

        deleteChatroom();
        this.dialogClose();
    }

    onMemberClick = (userProfile: UserProfile) => {
        const {
            removeUserFromChatroom,
        } = this.props;

        removeUserFromChatroom(userProfile.email, false);
    };

    onAddManager = (userProfile: UserProfile) => {
        const {
            room,
            addPermission,
        } = this.props;

        const name = selectProfileName(userProfile, selectProfileEmail(userProfile));

        const response = window.confirm(`Add ${name} as a manager?\nThis user will be able to add and remove other room members`);
        if (response) {
            addPermission(
                ENTITY_TYPE_CHATROOM,
                room.id,
                SUB_ENTITY_TYPE_MANAGER,
                NONE,
                true,
                true,
                true,
                true,
                'User',
                userProfile.userId,
                selectProfileName(userProfile),
                true
            );
        }
    };

    onRemoveManager = (userProfile: UserProfile) => {
        const {
            room,
            resourcePermissions,
            removePermission,
            setSagaMessage,
        } = this.props;

        let isOnlyManager = true;
        if (room && room.members) {
            const memberIds = room.memberOrder;
            for (let i = 0; i < memberIds.length; i += 1) {
                if (memberIds[i] !== userProfile.userId) {
                    if (hasResourcePermissions(
                        resourcePermissions,
                        ENTITY_TYPE_CHATROOM,
                        room.id,
                        SUB_ENTITY_TYPE_MANAGER,
                        null,
                        UPDATE,
                        memberIds[i],
                        'User'
                    )) {
                        isOnlyManager = false;
                        break;
                    }
                }
            }
        }

        if (isOnlyManager) {
            setSagaMessage(
                'You are the only manager',
                'Assign at least one new room manager before removing your manager permission',
                'OK',
                true
            );
        } else {
            const permissionId = this.getPermissionId(userProfile);

            const response = window.confirm(`Remove ${selectProfileName(userProfile)} as a manager?\n`
                + 'This user will no longer be able to add and remove other room members');
            if (response) {
                removePermission(ENTITY_TYPE_CHATROOM, room.id, permissionId);
            }
        }
    };

    onSelectSound = (sound: string) => {
        this.setState({
            selectedSound: NotificationSoundMap[sound],
        });
    };

    onRemoveManage = () => {
        const {
            room,
            saveChat,
        } = this.props;

        if (!room) {
            return;
        }

        const response = window.confirm('Remove manage status?\n'
            + 'Any room member will be able to add or remove other members. This action cannot be undone.');

        if (response) {
            saveChat(room.id, room.name, [], !room.managed, '', room.description);
        }
    };

    onShowFilesClick = () => {
        const {
            onShowFiles,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('files-click');

        onShowFiles();
    };

    onShowDonorDetailsClick = () => {
        const {
            room,
            onShowDonorDetails,
            openDonor,
        } = this.props;

        const donorId = room.donorId ? room.donorId : -1;
        openDonor(donorId);

        onShowDonorDetails();
    }

    onShowAddPeopleClick = () => {
        const {
            onShowAddPeople,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('add-people-click');

        onShowAddPeople();
    };

    onShowNotificationSoundsClick = () => {
        const {
            onShowNotificationSounds,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('notification-sounds-click');

        onShowNotificationSounds();
    };

    getPermissionId = (userProfile: UserProfile) => {
        const {
            resourcePermissions,
        } = this.props;

        for (let i = 0; i < resourcePermissions.length; i += 1) {
            if (resourcePermissions[i].authorized && resourcePermissions[i].authorized.authorizedType === 'User'
                && resourcePermissions[i].authorized.authorizedId === userProfile.userId) {
                if (resourcePermissions[i].target.subEntityType === SUB_ENTITY_TYPE_MANAGER) {
                    return resourcePermissions[i].permissionId;
                }
            }
        }

        return -1;
    }

    dialogClose = () => {
        this.setState({
            isDialogOpen: false,
        });
    };

    dialogOpen = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    playNotificationSound = (sound: string) => {
        const audio = new Audio(sounds[`${sound}`]);

        audio.play();
    };

    showUpdateButton = () => {
        const {
            room,
            editData,
            startEdit,
        } = this.props;

        const {
            showUpdateButton,
        } = this.state;

        if (!showUpdateButton) {
            this.setState({
                showUpdateButton: true,
            });

            startEdit(
                room.id,
                room.name,
                room.members,
                room.managed,
                '',
                room.description
            );
        } else {
            startEdit(
                editData.id,
                editData.name,
                editData.people,
                editData.managed,
                '',
                editData.description
            );
        }
    };

    hideUpdateButton = () => {
        const {
            onSubmitEdit,
        } = this.props;

        onSubmitEdit();

        this.setState({
            showUpdateButton: false,
        });
    };

    editOutOfFocus = () => {
        const {
            editData,
            room,
        } = this.props;

        if (room.name === editData.name && room.description === editData.description) {
            editData.id = null;
            this.setState({
                showUpdateButton: false,
            });
        }
    };

    saveNotificationSound = () => {
        const {
            room,
            onHideNotificationSounds,
            setNotificationSound,
        } = this.props;

        const {
            selectedSound,
        } = this.state;

        if (room.notificationSound !== selectedSound) {
            setNotificationSound(room.id, selectedSound);
        } else {
            onHideNotificationSounds();
        }
    };

    toggleMute = () => {
        const {
            room,
            toggleMute,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('toggle-mute-click');

        toggleMute(room.id);
    };

    allFilesClick = () => {
        this.setState({
            showAllFiles: true,
        });
    };

    myFilesClick = () => {
        this.setState({
            showAllFiles: false,
        });
    };

    goToFile = (file: ChatroomFile) => {
        const {
            room,
            setSelectedFile,
        } = this.props;

        setSelectedFile(room.id, file);

        this.setState({
            fullMediaView: true,
        });
    };

    goToAllFiles = () => {
        this.setState({
            fullMediaView: false,
        });
    };

    renderFieldError(fieldName: string, allowNonField: boolean) {
        const { editData, } = this.props;
        const { errors, } = editData;

        if (!errors) {
            return null;
        }

        let error = errors[fieldName];

        if (!error && allowNonField) {
            error = errors.nonFieldError;
        }

        if (!error) {
            return null;
        }

        return (
            <span
                style={fieldName === 'name' ? AuthStyles.formErrorChatroomDetails : AuthStyles.formError}
            >
                {error}
            </span>
        );
    }

    renderFileView() {
        const {
            user,
            accessToken,
            currentChatId,
            files,
            allFiles,
            myFiles,
            selectedFile,
            filesLoading,
            onHideFiles,
        } = this.props;

        const {
            members,
            name,
        } = this.props.room;

        const {
            showAllFiles,
            fullMediaView,
        } = this.state;

        const filesArray = showAllFiles ? allFiles : myFiles;
        const noFilesMessage = showAllFiles ? 'No files have been added to this room' : 'You have not uploaded any files to this room';

        if (fullMediaView) {
            return (
                <div>
                    <div style={ChatroomDetailsStyles.secondaryHeader}>
                        <div style={{ marginTop: 10, }}>
                            <BackButton
                                label="Back"
                                goBack={this.goToAllFiles}
                            />
                        </div>
                        <div style={ChatroomDetailsStyles.headerPaddingSecondary}>
                            <div style={ChatroomDetailsStyles.textWrapper}>
                                <div style={ChatroomDetailsStyles.centeredTitleLarge}>
                                    {selectedFile.displayName}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChatFileItem
                        key={selectedFile.id}
                        userId={user.userId}
                        chatId={currentChatId}
                        accessToken={accessToken}
                        file={selectedFile}
                        fullSize
                        memberName=""
                        onMediaClick={() => {}}
                    />
                </div>
            );
        }
        return (
            <div>
                <div style={ChatroomDetailsStyles.secondaryHeader}>
                    <div style={{ marginTop: 10, }}>
                        <BackButton
                            label="Back"
                            goBack={onHideFiles}
                        />
                    </div>
                    <div style={ChatroomDetailsStyles.headerPaddingSecondary}>
                        <div style={ChatroomDetailsStyles.textWrapper}>
                            <div style={ChatroomDetailsStyles.centeredTitleLarge}>
                                {truncate(`Files for ${name}`, 23)}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <TabSelector
                        labels={['All files', 'My files']}
                        selected={[showAllFiles, !showAllFiles]}
                        width={100}
                        borderRadius={false}
                        clickEvents={[this.allFilesClick, this.myFilesClick]}
                    />
                    {filesLoading
                        ? (
                            <div style={ChatListStyles.loadingIndicator}>
                                <LoadingIndicator />
                            </div>
                        ) : filesArray.length > 0
                            ? filesArray.map((fileId: MessageId) => (
                                <ChatFileItem
                                    key={fileId}
                                    userId={user.userId}
                                    chatId={currentChatId}
                                    accessToken={accessToken}
                                    file={files[fileId.toString()]}
                                    fullSize={false}
                                    memberName={
                                        members[files[fileId.toString()].memberId.toString()]
                                            ? selectProfileName(members[files[fileId.toString()].memberId.toString()].profile,
                                                selectProfileEmail(members[files[fileId.toString()].memberId.toString()].profile))
                                            : 'OmniLife User'
                                    }
                                    onMediaClick={() => this.goToFile(files[fileId.toString()])}
                                />
                            )) : (
                                <div style={ChatroomDetailsStyles.noFiles}>
                                    {noFilesMessage}
                                </div>
                            )}
                </div>
            </div>
        );
    }

    renderNotificationSound() {
        const {
            selectedSound,
        } = this.state;

        return (
            <div>
                <div style={ChatroomDetailsStyles.secondaryHeader}>
                    <div style={{ marginTop: 10, }}>
                        <BackButton
                            label="Back"
                            goBack={this.saveNotificationSound}
                        />
                    </div>
                    <div style={ChatroomDetailsStyles.headerPaddingSecondary}>
                        <div style={ChatroomDetailsStyles.textWrapper}>
                            <div style={ChatroomDetailsStyles.centeredTitleLarge}>
                                Notification Sound
                            </div>
                            <div style={ChatroomDetailsStyles.centeredTitleSmall}>
                                Your selection will be saved automatically.
                            </div>
                        </div>
                    </div>
                </div>
                <List>
                    {keys(NotificationSoundMap).map((sound) => (
                        <div key={sound}>
                            <ListDivider />
                            <div style={ChatroomDetailsStyles.notificationSoundItem}>
                                <button
                                    style={ChatroomDetailsStyles.playButton}
                                    type="button"
                                    onClick={() => this.playNotificationSound(sound)}
                                >
                                    <FontIcon
                                        className="iconBlue24"
                                        value="play_circle_outline"
                                        alt=""
                                    />
                                </button>
                                {NotificationSoundMap[sound]}
                                <div style={ChatroomDetailsStyles.radioButtonWrapper}>
                                    <RadioButton
                                        value={sound}
                                        checked={(selectedSound === NotificationSoundMap[sound])}
                                        onChange={() => this.onSelectSound(sound)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </List>
            </div>
        );
    }

    renderPresentMembers = () => {
        const {
            room,
            isConnected,
            user,
            avatars,
            permissions,
            resourcePermissions,
            verifiedOrgMember,
            showMemberProfile,
        } = this.props;

        if (!room.memberOrder) {
            return null;
        }

        const details = [(
            <p key={0} style={ChatroomDetailsStyles.titleMedium}><b>Active room members</b></p>
        )];

        const managed = room.managed || false;
        const hasManagerPermission = hasResourcePermissions(
            resourcePermissions,
            ENTITY_TYPE_CHATROOM,
            room.id,
            SUB_ENTITY_TYPE_MANAGER,
            null,
            UPDATE,
            user.userId,
            'User'
        );

        const isCurrentUserManager = managed ? managed && hasManagerPermission : false;

        const memberOrder = room && room.memberOrder ? room.memberOrder : [];
        for (let i = 0; i < memberOrder.length; i += 1) {
            const member = room.members[memberOrder[i].toString()];

            if (member.membershipStatus === 'Present') {
                const avatar = avatars[room.memberOrder[i]] || Images.circledDefaultProfile;

                const memberHasManagerPermission = hasResourcePermissions(
                    resourcePermissions,
                    ENTITY_TYPE_CHATROOM,
                    room.id,
                    SUB_ENTITY_TYPE_MANAGER,
                    null,
                    UPDATE,
                    member.profile.userId,
                    'User'
                );
                // isManager is used to mark which users have manager permissions
                const isManager = managed ? managed && memberHasManagerPermission : false;

                const canCreateChatroom = hasPermissions(permissions, ENTITY_TYPE_UI, CHATROOM_CREATE, NONE, NONE, READ);
                const isMainDonorChatroom = room && room.chatroomType ? room.chatroomType === 'Main Donor' : false;
                const isFollowerChatroom = room && room.chatroomType && room.chatroomType === 'Follower Group';

                const disabled = !room || !isConnected;
                const inactive = member.membershipStatus === 'Left'
                    || member.membershipStatus === 'Removed'
                    || member.membershipStatus === 'Expired';
                const archived = member.membershipStatus === 'Archived';

                let canBeRemoved;

                if (managed) {
                    canBeRemoved = canCreateChatroom && !isMainDonorChatroom && !isFollowerChatroom
                        && member.profile.userId !== user.userId && !disabled && !inactive && !archived && isCurrentUserManager;
                } else {
                    canBeRemoved = canCreateChatroom && !isMainDonorChatroom && !isFollowerChatroom
                        && member.profile.userId !== user.userId && !disabled && !inactive && !archived;
                }

                details.push((
                    <UserDetails
                        key={room.memberOrder[i]}
                        isManager={isManager}
                        isCurrentUserManager={isCurrentUserManager}
                        isConnected={isConnected}
                        startDate={member.startDate}
                        endDate={member.endDate}
                        membershipStatus={member.membershipStatus}
                        userProfile={member.profile}
                        avatar={avatar}
                        displayButton={canBeRemoved}
                        displayEmail={false}
                        doNotDisturb={member.doNotDisturb}
                        onMemberClick={this.onMemberClick}
                        addManager={this.onAddManager}
                        removeManager={this.onRemoveManager}
                        showMemberProfile={verifiedOrgMember ? showMemberProfile : () => {}}
                    />
                ));
            }
        }

        return details.length > 1 ? details : null;
    };

    renderNonPresentMembers = () => {
        const {
            room,
            isConnected,
            avatars,
            verifiedOrgMember,
            showMemberProfile,
        } = this.props;

        const details = [(
            <p key={0} style={ChatroomDetailsStyles.titleMedium}><b>Inactive room members</b></p>
        )];

        for (let i = 0; i < room.memberOrder.length; i += 1) {
            const member = room.members[room.memberOrder[i].toString()];

            if (member.membershipStatus === 'Left' || member.membershipStatus === 'Removed' || member.membershipStatus === 'Expired') {
                const avatar = avatars[room.memberOrder[i]] || Images.circledDefaultProfile;

                details.push((
                    <UserDetails
                        key={room.memberOrder[i]}
                        isConnected={isConnected}
                        startDate={member.startDate}
                        endDate={member.endDate}
                        membershipStatus={member.membershipStatus}
                        userProfile={member.profile}
                        avatar={avatar}
                        displayButton={false}
                        displayEmail={false}
                        onMemberClick={this.onMemberClick}
                        addManager={() => {}}
                        removeManager={() => {}}
                        doNotDisturb={member.doNotDisturb}
                        showMemberProfile={verifiedOrgMember ? showMemberProfile : () => {}}
                    />
                ));
            }
        }

        return details.length > 1 ? details : null;
    };

    renderArchivedMembers = () => {
        const {
            room,
            isConnected,
            avatars,
            verifiedOrgMember,
            showMemberProfile,
        } = this.props;

        const details = [(
            <p key={0} style={ChatroomDetailsStyles.titleMedium}><b>Archived room members</b></p>
        )];

        for (let i = 0; i < room.memberOrder.length; i += 1) {
            const member = room.members[room.memberOrder[i].toString()];

            if (member.membershipStatus === 'Archived') {
                const avatar = avatars[room.memberOrder[i]] || Images.circledDefaultProfile;

                details.push((
                    <UserDetails
                        key={room.memberOrder[i]}
                        isConnected={isConnected}
                        startDate={member.startDate}
                        endDate={member.endDate}
                        membershipStatus={member.membershipStatus}
                        userProfile={member.profile}
                        avatar={avatar}
                        displayButton={false}
                        displayEmail={false}
                        onMemberClick={this.onMemberClick}
                        addManager={() => {}}
                        removeManager={() => {}}
                        doNotDisturb={member.doNotDisturb}
                        showMemberProfile={verifiedOrgMember ? showMemberProfile : () => {}}
                    />
                ));
            }
        }

        return details.length > 1 ? details : null;
    };

    renderAddPeople() {
        const {
            user,
            room,
            avatars,
            chatroomState,
            peopleFilter,
            isConnected,
            teams,
            teamsLoading,
            onHideAddPeople,
            addUsersToChatroom,
            inviteExistingUser,
            inviteNewUser,
            clearMessage,
            filterPeople,
        } = this.props;

        const {
            contactsOrder,
            contactsMap,
            contactsAreLoaded,
            singleUserAdded,
            newUserInvited,
            addSingleUserError,
            inviteNewUserError,
        } = chatroomState;

        return (
            <div style={ChatroomDetailsStyles.mainContainer}>
                <AddPeople
                    memberId={user.userId}
                    members={room.members}
                    avatars={avatars}
                    teams={teams}
                    teamsLoading={teamsLoading}
                    contactsOrder={contactsOrder}
                    contactsMap={contactsMap}
                    contactsAreLoaded={contactsAreLoaded}
                    singleUserAdded={singleUserAdded}
                    newUserInvited={newUserInvited}
                    addSingleUserError={addSingleUserError}
                    inviteNewUserError={inviteNewUserError}
                    peopleFilter={peopleFilter}
                    createChatroom={false}
                    isConnected={isConnected}
                    onHideAddPeople={onHideAddPeople}
                    onAddUsers={addUsersToChatroom}
                    inviteExistingUser={inviteExistingUser}
                    inviteNewUser={inviteNewUser}
                    clearMessage={clearMessage}
                    filterPeople={filterPeople}
                />
            </div>
        );
    }

    renderChatroomDetails() {
        const {
            room,
            user,
            editData,
            isConnected,
            permissions,
            resourcePermissions,
            setDescription,
            setName,
            closeChatroomDetails,
        } = this.props;

        const {
            showUpdateButton,
            isDialogOpen,
        } = this.state;

        const lastActiveTime = room.lastMessage ? distanceToNow(room.lastMessage.sentTime) : null;

        const managed = room.managed || false;
        const hasManagerPermission = hasResourcePermissions(
            resourcePermissions,
            ENTITY_TYPE_CHATROOM,
            room.id,
            SUB_ENTITY_TYPE_MANAGER,
            null,
            UPDATE,
            user.userId,
            'User'
        );

        const isCurrentUserManager = managed ? managed && hasManagerPermission : false;
        const canViewManageToggle = isCurrentUserManager;

        const canCreateChatroom = hasPermissions(permissions, ENTITY_TYPE_UI, CHATROOM_CREATE, NONE, NONE, READ);
        const canViewDonorDetails = room.donorId ? hasPermissions(permissions, ENTITY_TYPE_DONOR, room.donorId, SUB_ENTITY_TYPE_DETAILS, NONE, READ) : false;
        const isMainDonorChatroom = room && room.chatroomType && room.chatroomType === 'Main Donor';
        const isFollowerChatroom = room && room.chatroomType && room.chatroomType === 'Follower Group';
        const isCaseChatroom = room && room.chatroomType
            ? (isMainDonorChatroom
            || isFollowerChatroom
            || room.chatroomType === 'Donor Only'
            || room.chatroomType === 'Case')
            && (room.donorId) : false;

        const addDisabled = !room || !isConnected || isMainDonorChatroom || isFollowerChatroom
            || !canCreateChatroom;// || (managed && !isCurrentUserManager);
        const leaveDisabled = !room || !isConnected || isMainDonorChatroom || (isFollowerChatroom && !room.followerId);
        const removeDisabled = !room || !isConnected || isMainDonorChatroom || isFollowerChatroom;

        let disabledText = '';
        let disabledDeleteText = '';
        if (addDisabled) {
            if (isMainDonorChatroom) {
                disabledText = 'Room members are managed by Case Permissions';
            } else if (isFollowerChatroom) {
                disabledText = 'Room members are managed by Case Followers';
                disabledDeleteText = 'Follower Groups can be deleted from the Case Permissions tab';
            } else if (!canCreateChatroom) {
                disabledText = 'Room members can\'t be updated by free users';
            } else if (managed && !isCurrentUserManager) {
                disabledText = 'Room members are managed by the room managers';
            }
        }

        return (
            <div style={ChatroomDetailsStyles.mainContainer}>
                <ReactTooltip />
                <div>
                    <div style={ChatroomDetailsStyles.header}>
                        <div style={{ marginTop: 10, }}>
                            <BackButton
                                light
                                label="Close"
                                goBack={closeChatroomDetails}
                            />
                        </div>
                        <div style={ChatroomDetailsStyles.headerPadding}>
                            <input
                                style={ChatroomDetailsStyles.nameInput}
                                type="text"
                                maxLength={255}
                                disabled={!isConnected || isMainDonorChatroom || isFollowerChatroom}
                                data-tip={!isConnected || isMainDonorChatroom || isFollowerChatroom
                                    ? 'Cannot edit Main Case or Follower chatroom names'
                                    : 'Click here to update the name'}
                                data-delay-show="250"
                                data-effect="solid"
                                data-place="bottom"
                                value={(editData.id && editData.name !== room.name) && editData.id ? editData.name : transformCaseName(room.name)}
                                onChange={(event) => setName(event.target.value)}
                                onFocus={this.showUpdateButton}
                                onBlur={this.editOutOfFocus}
                            />
                            {this.renderFieldError('name', true)}
                            <br />
                            <span style={ChatroomDetailsStyles.titleSmall}>
                                {room.memberCount}
                                {' Members  -  Active '}
                                {lastActiveTime}
                                {' ago'}
                            </span>
                        </div>
                    </div>
                    <div style={ChatroomDetailsStyles.descriptionArea}>
                        <div style={ChatroomDetailsStyles.leftAlign}>
                            <span style={ChatroomDetailsStyles.titleMedium}><b>Group Description</b></span>
                        </div>
                        <input
                            style={ChatroomDetailsStyles.textInput}
                            type="text"
                            maxLength={255}
                            disabled={!isConnected}
                            data-tip="Click here to update the description"
                            data-delay-show="250"
                            data-effect="solid"
                            data-place="bottom"
                            value={(editData.id && editData.description !== room.description) ? editData.description : room.description}
                            onChange={(description) => setDescription(description)}
                            onFocus={this.showUpdateButton}
                            onBlur={this.editOutOfFocus}
                        />
                        <br />
                        { showUpdateButton ? (
                            <button
                                style={isConnected ? ChatroomDetailsStyles.updateButton : ChatroomDetailsStyles.updateButtonDisabled}
                                type="submit"
                                disabled={!isConnected}
                                onClick={this.hideUpdateButton}
                                data-tip="Update new name and/or description"
                                data-delay-show="500"
                                data-effect="solid"
                            >
                                Update
                            </button>
                        ) : null}
                    </div>
                </div>
                <div style={ChatroomDetailsStyles.actionArea}>
                    {canViewManageToggle ? (
                        <div>
                            <div
                                style={ChatroomDetailsStyles.commandArea}
                                className="lineButtons"
                                role="button"
                                tabIndex="0"
                                onKeyDown={this.onRemoveManage}
                                onClick={this.onRemoveManage}
                                data-tip="Remove manage status"
                                data-delay-show="500"
                                data-effect="solid"
                            >
                                Remove manage status
                            </div>
                            <div style={ChatroomDetailsStyles.border} />
                        </div>
                    ) : null}
                    <div
                        style={ChatroomDetailsStyles.commandArea}
                        className="lineButtons"
                        role="button"
                        tabIndex="0"
                        onKeyDown={this.onShowFilesClick}
                        onClick={this.onShowFilesClick}
                        data-tip="View all files for this room"
                        data-delay-show="500"
                        data-effect="solid"
                    >
                        Files
                    </div>
                    <div style={ChatroomDetailsStyles.border} />
                    {(isCaseChatroom && canViewDonorDetails) || isFollowerChatroom ? (
                        <>
                            <div
                                style={ChatroomDetailsStyles.commandArea}
                                className="lineButtons"
                                role="button"
                                tabIndex="0"
                                onKeyDown={this.onShowDonorDetailsClick}
                                onClick={this.onShowDonorDetailsClick}
                                data-tip="View the case details"
                                data-delay-show="500"
                                data-effect="solid"
                            >
                                Case Info
                            </div>
                            <div style={ChatroomDetailsStyles.border} />
                        </>
                    ) : null}
                    {disabledText ? (
                        <div style={ChatroomDetailsStyles.addPeopleDisabledTextWrapper}>
                            <span style={ChatroomDetailsStyles.addPeopleDisabledText}>{disabledText}</span>
                        </div>
                    ) : null}
                    <div
                        style={!addDisabled ? ChatroomDetailsStyles.commandArea : ChatroomDetailsStyles.commandAreaDisabled}
                        className="lineButtons"
                        role="button"
                        tabIndex="0"
                        onKeyDown={!addDisabled ? this.onShowAddPeopleClick : null}
                        onClick={!addDisabled ? this.onShowAddPeopleClick : null}
                        data-tip="Add users to this room"
                        data-delay-show="500"
                        data-effect="solid"
                    >
                        Add people
                    </div>

                    <div style={ChatroomDetailsStyles.border} />

                    <div
                        style={!leaveDisabled ? ChatroomDetailsStyles.commandAreaLeave : ChatroomDetailsStyles.commandAreaDisabled}
                        role="button"
                        tabIndex="0"
                        onKeyDown={!leaveDisabled ? this.onLeaveGroup : null}
                        onClick={!leaveDisabled ? this.onLeaveGroup : null}
                        data-tip="Leave this room. To rejoin you have to be re-invited."
                        data-delay-show="500"
                        data-effect="solid"
                    >
                        Leave group
                    </div>
                </div>
                <br />
                <div style={ChatroomDetailsStyles.actionArea}>
                    <div
                        style={ChatroomDetailsStyles.muteCommandArea}
                        role="button"
                        tabIndex="0"
                    >
                        <p>Mute group</p>
                        <Switch
                            checked={room.doNotDisturb}
                            ripple={false}
                            onChange={this.toggleMute}
                        />
                    </div>

                    <div style={ChatroomDetailsStyles.border} />

                    <div
                        style={ChatroomDetailsStyles.notificationSoundCommandArea}
                        role="button"
                        tabIndex="0"
                        onKeyDown={this.onShowNotificationSoundsClick}
                        onClick={this.onShowNotificationSoundsClick}
                        data-tip="Change notifcation sound"
                        data-delay-show="500"
                        data-effect="solid"
                    >
                        <p style={ChatroomDetailsStyles.notificationSoundTitleText}>Notification sound</p>
                        <p style={ChatroomDetailsStyles.notificationSoundNameText}>{room.notificationSound}</p>
                    </div>
                </div>
                <br />
                <div style={ChatroomDetailsStyles.actionArea}>
                    <div style={ChatroomDetailsStyles.border} />
                    {disabledDeleteText ? (
                        <div style={ChatroomDetailsStyles.addPeopleDisabledTextWrapper}>
                            <span style={ChatroomDetailsStyles.addPeopleDisabledText}>{disabledDeleteText}</span>
                        </div>
                    ) : null}
                    <div
                        style={!removeDisabled ? ChatroomDetailsStyles.commandAreaDelete : ChatroomDetailsStyles.commandAreaDeleteDisabled}
                        role="button"
                        tabIndex="0"
                        onKeyDown={!removeDisabled ? this.dialogOpen : null}
                        onClick={!removeDisabled ? this.dialogOpen : null}
                        data-tip="This will delete the chatroom for all members."
                        data-delay-show="500"
                        data-effect="solid"
                    >
                        Delete Room
                        <FontIcon
                            id="delete_warning"
                            value={!removeDisabled ? 'report_problem' : null}
                            alt="Warning"
                        />
                    </div>
                    <Dialog
                        active={isDialogOpen}
                        onEscKeyDown={this.dialogClose}
                        onOverlayClick={this.dialogClose}
                        title={`Delete ${room.name}?`}
                    >
                        <div>The chatroom cannot be recovered once deleted.</div>
                        <div style={ChatroomDetailsStyles.dialogActionsWrapper}>
                            <button
                                style={ChatroomDetailsStyles.dialogCancelButton}
                                type="button"
                                onClick={this.dialogClose}
                            >
                                Cancel
                            </button>
                            <button
                                style={ChatroomDetailsStyles.dialogDeleteButton}
                                type="button"
                                onClick={this.onDeleteChatroom}
                            >
                                Delete
                            </button>
                        </div>
                    </Dialog>
                </div>

                <div style={ChatroomDetailsStyles.userArea}>
                    {this.renderPresentMembers()}
                    {this.renderNonPresentMembers()}
                    {this.renderArchivedMembers()}
                </div>
            </div>
        );
    }

    render() {
        const {
            isFileViewVisible,
            isAddPeopleVisible,
            isNotificationSoundsVisible,
        } = this.props;

        let componentBody = '';
        if (isAddPeopleVisible) {
            componentBody = this.renderAddPeople();
        } else if (isNotificationSoundsVisible) {
            componentBody = this.renderNotificationSound();
        } else if (isFileViewVisible) {
            componentBody = this.renderFileView();
        } else {
            componentBody = this.renderChatroomDetails();
        }

        return (
            <div>
                {componentBody}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const currentChatId = state.chatList.activeChatId;

    return {
        currentChatId,
        accessToken: state.auth.accessToken,
        verifiedOrgMember: state.auth.profile.verifiedOrgMember,
        avatars: state.chatList.avatars || {},
        files: state.chatList.chats[`${currentChatId}`].files || {},
        allFiles: state.chatList.chats[`${currentChatId}`].allFiles || [],
        myFiles: state.chatList.chats[`${currentChatId}`].myFiles || [],
        selectedFile: state.chatList.chats[`${currentChatId}`].selectedFile || {},
        filesLoading: state.loading.chatFiles[currentChatId],
        isConnected: state.chatList.socketStatus === 'connected',

        permissions: state.permission.permissions || [],
        donors: state.donor.donors || {},
        resourcePermissions: currentChatId && state.permission.resourcePermissions[ENTITY_TYPE_CHATROOM]
        && state.permission.resourcePermissions[ENTITY_TYPE_CHATROOM][currentChatId]
            ? state.permission.resourcePermissions[ENTITY_TYPE_CHATROOM][currentChatId] : [],
    };
};

export default connect(mapStateToProps, {
    startEdit: _startEdit,
    saveChat: _saveChat,
    addPermission: _addPermission,
    removePermission: _removePermission,
    setSelectedFile: _setSelectedFile,
    setSagaMessage: _setSagaMessage,
    logFirebaseEvent: _logFirebaseEvent,
    openDonor: _openDonor,
    getDonor: _getDonor,
})(ChatroomDetails);
