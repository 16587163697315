// @flow

// $FlowFixMe
import { createSelector } from 'reselect';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import type {
    CaseRow,
    Donor,
    DonorSortBy,
    SortDirection,
    WorkflowOption,
} from '../Utils/types';
import { values } from '../Utils/Object';
import {
    parseDate,
    formatDate,
    formatDateTime,
    calculateAgeFromDOB,
} from '../Utils/time';
import { donorSortDefault } from '../Redux/DonorActions';

const getWorkflowOptions = (_state, workflowOptions: WorkflowOption[]) => workflowOptions;

const getDonors = (state) => state.donor.donors;
export const getDonorFilter = (state: any) => {
    if (state.auth.profile && state.auth.profile.donorFilter) {
        return { ...state.auth.profile.donorFilter, filterTags: state.donor.caseTagFilter, workflowTypes: state.donor.workflowTypes, };
    }

    return {
        status: 'All', donorType: 'All', filterTags: [], workflowTypes: [],
    };
};

const getDonorSort = (state) => state.donor?.sort ?? donorSortDefault;

export const getTagFilter = (state: any) => {
    if (state.donor && state.donor.caseTagFilter) {
        return state.donor.caseTagFilter;
    }
    return null;
};

const getWorkflowTypes = (state) => {
    if (state.donor && state.donor.workflowTypes) {
        return state.donor.workflowTypes;
    }
    return null;
};

const stringSort = (a: string, b: string): number => a.toLocaleUpperCase().localeCompare(b.toLocaleUpperCase());
const numberSort = (a: number, b: number): number => a - b;

const sortDonors = (rowA: CaseRow, rowB: CaseRow, donorSortBy: DonorSortBy, donorSortDirection: SortDirection): number => {
    const direction = donorSortDirection === 'asc' ? 1 : -1;

    switch (donorSortBy) {
        case 'NAME':
            // Case insensitive alphabetical sorting by name
            return stringSort(rowA.name, rowB.name) * direction;
        case 'WORKFLOW':
            // Case insensitive alphabetical sorting by workflow name
            return stringSort(rowA.workflow, rowB.workflow) * direction;
        case 'PROGRESS': {
            // Sort by percentage of tasks completed
            const aPercentage = rowA.tasksCompleted / rowA.tasksTotal;
            const bPercentage = rowB.tasksCompleted / rowB.tasksTotal;

            if (aPercentage === bPercentage) {
                return 0;
            }

            if (Number.isNaN(aPercentage)) {
                return -1 * direction;
            }

            if (Number.isNaN(bPercentage)) {
                return 1 * direction;
            }

            return numberSort(aPercentage, bPercentage) * direction;
        }
        case 'LAST_ACTIVITY':
            // Sort by last activity date
            return numberSort(rowA.lastActivity.comp, rowB.lastActivity.comp) * direction;
        case 'NEXT_DUE_DATE':
            // Sort by next due date
            return numberSort(rowA.nextDueDate.comp, rowB.nextDueDate.comp) * direction;
        case 'NOTES':
            // Sort by last note date
            return (
                rowA.lastNote && rowB.lastNote
                    ? numberSort(
                        rowA.lastNote.createDate ? Date.parse(rowA.lastNote.createDate) : 0,
                        rowB.lastNote.createDate ? Date.parse(rowB.lastNote.createDate) : 0
                    )
                    : rowA.lastNote && !rowB.lastNote
                        ? 1
                        : !rowA.lastNote && rowB.lastNote
                            ? -1
                            : numberSort(rowA.createdOn.comp, rowB.createdOn.comp)
            ) * direction;

        case 'CREATE_DATE':
        default:
            // Sort by create date number
            return numberSort(rowA.createdOn.comp, rowB.createdOn.comp) * direction;
    }
};

const getCaseRows = createSelector([getDonors, getWorkflowOptions], (donors, workflowOptions): CaseRow[] => {
    const rows = values<Donor>(donors).map((donor) => {
        const {
            donorId,
            opoDonorId,
            unosId,
            currentLocation,
            sex,
            dob,
            tags,
            closed,
            highRisk,
            notes,
        } = donor;

        const caseWorkflow = workflowOptions.find((workflow) => workflow.key === donor.workflow);
        const workflowName = caseWorkflow ? caseWorkflow.name : '';
        const createdOn = donor.createDate;
        const sexAbbreviation = sex === 'Male' ? 'M' : (sex === 'Female' ? 'F' : 'U');
        const age = dob ? calculateAgeFromDOB(dob) : undefined;

        // Task info
        const maxTasks = (donor.tasks) ? donor.tasks.length : -1;
        const completedTasks = (donor.tasks || []).reduce((completed, task) => {
            if (task.completed) {
                return completed + 1;
            }

            return completed;
        }, 0);

        // We need to determine our latest task activity, so look through all tasks that have a lastModifiedTaskDate and collect them
        // then sort them in descending order
        const taskActivity = (donor.tasks || []).filter((task) => task.lastModified).map((task) => task.lastModified).sort((t1, t2) => {
            const t1Date = parseDate(t1);
            const t2Date = parseDate(t2);

            if (t1Date === null && t2Date === null) return 0;
            if (t1Date === null) return 1;
            if (t2Date === null) return -1;

            return (t2Date.getTime() - t1Date.getTime());
        });

        // We also need to determine our next due date, if any, do a similar filter and sort them in ascending order according to
        // the time difference from now (soonest time first)
        const now = Date.now();
        const dueDates = (donor.tasks || []).filter((task) => (!task.completed && task.dueDate)).map((task) => task.dueDate).sort((t1, t2) => {
            const t1Date = parseDate(t1);
            const t2Date = parseDate(t2);

            if (t1Date === null && t2Date === null) return 0;
            if (t1Date === null) return 1;
            if (t2Date === null) return -1;

            const t1Diff = differenceInMilliseconds(t1Date, now);
            const t2Diff = differenceInMilliseconds(t2Date, now);

            return (t1Diff - t2Diff);
        });
        const nextDueDate = (dueDates.length) ? parseDate(dueDates[0]) : null;

        const lastActivity = taskActivity[0] || donor.lastModified;
        const lastNote = notes && notes.length > 0 ? notes[0] : null;

        return {
            donorId,
            unosId,
            name: opoDonorId ?? unosId,
            sex: sexAbbreviation,
            age,
            location: currentLocation ?? '',
            workflow: workflowName,
            createdOn: {
                value: formatDate(createdOn) ?? '',
                comp: new Date(createdOn ?? 0).valueOf(), // convert to number for easy comparison
            },
            lastActivity: {
                value: formatDateTime(lastActivity, '\' at \''),
                comp: new Date(lastActivity ?? 0).valueOf(), // convert to number for easy comparison
            },
            nextDueDate: {
                value: nextDueDate ? formatDateTime(nextDueDate.toISOString(), '\' at \'') : '',
                comp: nextDueDate ? new Date(nextDueDate).valueOf() : Number.MAX_SAFE_INTEGER, // convert to number for easy comparison
            },
            tasksCompleted: completedTasks,
            tasksTotal: maxTasks,
            tags,
            closed,
            highRisk,
            donor,
            lastNote,
        };
    });

    return rows;
});

export const getFilteredSortedDonorsSelector = createSelector(
    [getCaseRows, getDonorFilter, getDonorSort, getTagFilter, getWorkflowTypes],
    (rows, donorFilter, sort, tagFilters, workflowTypes) => (
        rows.filter((row) => {
            // Filter by status
            switch (donorFilter.status) {
                case 'Open':
                    return !row.closed;
                case 'Closed':
                    return row.closed;
                case 'HighRisk':
                    return row.highRisk && !row.closed;
                case 'Normal':
                    return !row.highRisk && !row.closed;
                case 'All':
                default:
                    return true;
            }
        }).filter((row) => {
            // Filter by tags
            if (tagFilters.length > 0) {
                return (row.tags || []).some((tag) => tagFilters.includes(tag.tagId.toString()));
            }

            return true;
        }).filter((row) => {
            // Filter by workflow
            if (workflowTypes.length > 0) {
                // NOTE: Workflows are now built with the Workflow Builder, which allows for updating existing workflows. In those
                //     : cases, the Workflow key is automatically appending with a '-v<d>' suffix to indicate the current version
                //     : of the workflow and associate a lineage from the parent workflow. When we are filtering the cases by
                //     : Workflow, the user really only cares what the name of the workflow appears as - to achieve that we trim
                //     : the version identifier from the key and match on the non-versioned part of the key
                return workflowTypes.includes(row.donor.workflow.replace(/-v\d+$/, ''));
            }

            return true;
        })
            .sort((a, b) => sortDonors(a, b, sort.column, sort.direction))
    )
);
