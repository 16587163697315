// @flow

import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import api, { useApiFetch } from '../Services/Api';
import type { WorkflowOption, WorkflowAndForms } from './types';
import { transformWorkflowDef, transformFormData } from './TaskDetailsUtils';

const useWorkflow = (workflowKey: string) => {
    const apiFetch = useApiFetch();

    return useQuery<WorkflowAndForms | void>({
        queryKey: ['workflowDefinition', workflowKey],
        queryFn: async () => {
            if (!workflowKey || workflowKey === 'LEGACY') {
                return null;
            }

            return apiFetch(api.txp.workflowDefinition, { kwargs: { key: workflowKey, }, });
        },
    });
};

/**
 * Get a workflow definition, including forms, given the workflow key
 * Provides useQuery response
 *
 * @param {string} workflowKey
 */
export const useWorkflowDefinition = (workflowKey: string) => {
    const { data, ...theRest } = useWorkflow(workflowKey);

    return {
        ...theRest,
        data: data ? transformWorkflowDef(data) : undefined,
    };
};

/**
 * Get the form definitions for a workflow given the workflow key
 * Provides useQuery response
 *
 * @param {string} workflowKey
 */
export const useWorkflowForms = (workflowKey: string) => {
    const defaultFormDefinitions = useSelector((state) => state.forms.formDef);
    const { data, ...theRest } = useWorkflow(workflowKey);

    return {
        ...theRest,
        data: {
            ...defaultFormDefinitions,
            ...transformFormData(data?.forms ?? []),
        },
    };
};

/**
 * Provides workflow options as an array of key, name pairs
 * Provides useQuery response
 *
 * @returns {Array<WorkflowOption>}
 */
export const useWorkflowOptions = () => {
    const apiFetch = useApiFetch();

    return useQuery<WorkflowOption[]>({
        queryKey: ['workflowOptions'],
        queryFn: async () => apiFetch(api.txp.workflowOptions),
    });
};
